/** @format */

const firstOrderXml = id => {
  return `<?xml version="1.0" encoding="utf-8"?>
    <DieCutLabel Version="8.0" Units="twips" MediaType="Default">
      <PaperOrientation>Portrait</PaperOrientation>
      <Id>Small30334</Id>
      <PaperName>30334 2-1/4 in x 1-1/4 in</PaperName>
      <DrawCommands>
        <RoundRectangle X="0" Y="0" Width="3240" Height="1800" Rx="270" Ry="270"/>
      </DrawCommands>
      <ObjectInfo>
        <TextObject>
          <Name>TEXT</Name>
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
          <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
          <LinkedObjectName></LinkedObjectName>
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>False</IsVariable>
          <HorizontalAlignment>CenterBlock</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <TextFitMode>AlwaysFit</TextFitMode>
          <UseFullFontHeight>True</UseFullFontHeight>
          <Verticalized>False</Verticalized>
          <StyledText>
            <Element>
              <String>First Order! - ${id}</String>
              <Attributes>
                <Font Family="Arial" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
              </Attributes>
            </Element>
          </StyledText>
        </TextObject>
        <Bounds X="57.6" Y="86.39999" Width="3124.8" Height="1627.2"/>
      </ObjectInfo>
    </DieCutLabel>
    `;
};

const orderWith285Xml = qty => {
  let s = 's';
  if (qty === 1) {
    s = '';
  }

  return `<?xml version="1.0" encoding="utf-8"?>
    <DieCutLabel Version="8.0" Units="twips" MediaType="Default">
      <PaperOrientation>Portrait</PaperOrientation>
      <Id>Small30334</Id>
      <PaperName>30334 2-1/4 in x 1-1/4 in</PaperName>
      <DrawCommands>
        <RoundRectangle X="0" Y="0" Width="3240" Height="1800" Rx="270" Ry="270"/>
      </DrawCommands>
      <ObjectInfo>
        <TextObject>
          <Name>TEXT</Name>
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
          <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
          <LinkedObjectName></LinkedObjectName>
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>False</IsVariable>
          <HorizontalAlignment>CenterBlock</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <TextFitMode>AlwaysFit</TextFitMode>
          <UseFullFontHeight>True</UseFullFontHeight>
          <Verticalized>False</Verticalized>
          <StyledText>
            <Element>
              <String>2.85mm: ${qty}Roll${s}</String>
              <Attributes>
                <Font Family="Arial" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
              </Attributes>
            </Element>
          </StyledText>
        </TextObject>
        <Bounds X="57.6" Y="86.39999" Width="3124.8" Height="1627.2"/>
      </ObjectInfo>
    </DieCutLabel>
    `;
};

const pickListXml = (lines, itemDetails) => {
  return `<?xml version="1.0" encoding="utf-8"?>
    <DieCutLabel Version="8.0" Units="twips" MediaType="Default">
      <PaperOrientation>Portrait</PaperOrientation>
      <Id>Small30334</Id>
      <PaperName>30334 2-1/4 in x 1-1/4 in</PaperName>
      <DrawCommands>
        <RoundRectangle X="0" Y="0" Width="3240" Height="1800" Rx="270" Ry="270"/>
      </DrawCommands>
      <ObjectInfo>
        <TextObject>
          <Name>TEXT</Name>
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
          <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
          <LinkedObjectName></LinkedObjectName>
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>False</IsVariable>
          <HorizontalAlignment>CenterBlock</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <TextFitMode>AlwaysFit</TextFitMode>
          <UseFullFontHeight>True</UseFullFontHeight>
          <Verticalized>False</Verticalized>
          <StyledText>
            <Element>
              <String>${lines
                .map(line => {
                  let {material, color, diameter, weight} = line.options;
                  color = color.replace('&', '&amp;');
                  if (material == 'Bulk') {
                    weight = '1kg';
                    diameter = '';
                  }
                  let colors = color.split(', ');
                  if (colors.length === 1) {
                    return `${material} ${color} ${weight} ${diameter} qty: ${line.qty}`;
                  } else {
                    let ColorString = createColorStrings(colors).join('\n');
                    if (line.sides && line.sides.length > 0) {
                      let newColorStrings = line.sides.map(side => {
                        return (
                          itemDetails[side].tags.color +
                          ' ' +
                          itemDetails[side].tags.diameter
                        );
                      });
                      ColorString = createColorStrings(newColorStrings).join(
                        '\n'
                      );
                    }

                    return `${material} ${weight} ${diameter} qty: ${line.qty}\nColors: ${ColorString}`;
                  }
                })
                .join('\r\n')}</String>
              <Attributes>
                <Font Family="Arial" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
              </Attributes>
            </Element>
          </StyledText>
        </TextObject>
        <Bounds X="57.6" Y="86.39999" Width="3124.8" Height="1627.2"/>
      </ObjectInfo>
    </DieCutLabel>
    `;
};

const orderLabelXml = order => {
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  let qtys = order.lines.map(line => line.qty);
  let qty = qtys.reduce(reducer, 0);
  return `<?xml version="1.0" encoding="utf-8"?>
    <DieCutLabel Version="8.0" Units="twips" MediaType="Default">
      <PaperOrientation>Portrait</PaperOrientation>
      <Id>Small30334</Id>
      <PaperName>30334 2-1/4 in x 1-1/4 in</PaperName>
      <DrawCommands>
        <RoundRectangle X="0" Y="0" Width="3240" Height="1800" Rx="270" Ry="270"/>
      </DrawCommands>
      <ObjectInfo>
        <TextObject>
          <Name>TEXT</Name>
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
          <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
          <LinkedObjectName></LinkedObjectName>
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>False</IsVariable>
          <HorizontalAlignment>Center</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <TextFitMode>AlwaysFit</TextFitMode>
          <UseFullFontHeight>True</UseFullFontHeight>
          <Verticalized>False</Verticalized>
          <StyledText>
            <Element>
              <String>${order.shipping.firstName} ${order.shipping.lastName}
    zip: ${order.shipping.zipCode}
    total qty: ${qty}</String>
              <Attributes>
                <Font Family="Arial" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
              </Attributes>
            </Element>
          </StyledText>
        </TextObject>
        <Bounds X="1492.481" Y="116.4187" Width="1689.919" Height="1597.181"/>
      </ObjectInfo>
      <ObjectInfo>
        <BarcodeObject>
          <Name>BARCODE</Name>
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
          <BackColor Alpha="255" Red="255" Green="255" Blue="255"/>
          <LinkedObjectName></LinkedObjectName>
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>False</IsVariable>
          <Text>${order.id}</Text>
          <Type>QRCode</Type>
          <Size>Large</Size>
          <TextPosition>None</TextPosition>
          <TextFont Family="Arial" Size="7" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
          <CheckSumFont Family="Arial" Size="10" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
          <TextEmbedding>None</TextEmbedding>
          <ECLevel>0</ECLevel>
          <HorizontalAlignment>Center</HorizontalAlignment>
          <QuietZonesPadding Left="0" Right="0" Top="0" Bottom="0"/>
        </BarcodeObject>
        <Bounds X="57.6" Y="86.39999" Width="1346.962" Height="1627.2"/>
      </ObjectInfo>
    </DieCutLabel>  
    `;
};

const boxLabelXml = (line, itemDetails) => {
  let {material, color, diameter, weight, colorName} = line.tags
    ? line.tags
    : line.options;
  const {temp} = line.data || {};
  if (colorName) {
    colorName = colorName.replace('&', '&amp;');
  }
  const plasticSpool = line.sides?.at(-1) === '66ce1144e47902bb27e8f0ad';
  let tempRange = [190, 230];
  let colors = [];

  let url = `https://www.printbed.com/item/${material}?color=${encodeURIComponent(
    color.toLowerCase()
  )}&amp;amount=${weight}&amp;diameter=${diameter}`;
  switch (material) {
    case 'PETG':
      tempRange = [225, 255];
      material = 'PETG';
      break;
    case 'FORGE':
      tempRange = [190, 230];
      material = 'Forge PLA+';
      break;
    case 'Kits':
      material = color + ' KIT PLA';
      if (line.sides && line.sides.length > 0) {
        colors = line.sides.map(side => {
          return itemDetails[side].tags.color;
        });
      }
      break;
    case 'MINI':
      material = 'PLA';
      colors = color.split(', ');
      url = `https://www.printbed.com/item/minis`;
      break;
    case 'Bulk':
      material = 'PLA';
      diameter = '';
      colors = color.split(', ');
      if (line.sides && line.sides.length > 0) {
        colors = line.sides.map(side => {
          return (
            itemDetails[side].tags.color + ' ' + itemDetails[side].tags.diameter
          );
        });
      }
      url = `https://www.printbed.com/item/bulk`;
      break;
    default:
      break;
  }

  if (weight === '5kg') {
    colorName += ' MEGA';
  }

  if (colors.length > 1) {
    colors = createColorStrings(colors);
  }

  let colorString = colors
    .map((color, index) => {
      if (index % 2 === 0 && index > 1) {
        let secondColor = '';
        if (index + 1 < colors.length) {
          secondColor = `, ${colors[index + 1].toTitleCase()}`;
        }
        return `<Element>
                  <String>
                ${color.toTitleCase()}${secondColor}</String>
                  <Attributes>
                    <Font Family="Arial" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
                    <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
                  </Attributes>
                </Element>`;
      } else {
        return '';
      }
    })
    .join('\n');

  let range = tempRange[1] - tempRange[0];
  let recTemp = temp - tempRange[0];
  let lineWidth = 2619.842;
  let startPos = 336.6957;
  let linePos = (lineWidth / range) * recTemp + startPos;

  let topTemp = '';

  if (temp !== tempRange[1]) {
    topTemp = `<ObjectInfo>
      <TextObject>
        <Name>TEXT_2</Name>
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
        <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
        <LinkedObjectName></LinkedObjectName>
        <Rotation>Rotation0</Rotation>
        <IsMirrored>False</IsMirrored>
        <IsVariable>False</IsVariable>
        <HorizontalAlignment>Left</HorizontalAlignment>
        <VerticalAlignment>Middle</VerticalAlignment>
        <TextFitMode>None</TextFitMode>
        <UseFullFontHeight>True</UseFullFontHeight>
        <Verticalized>False</Verticalized>
        <StyledText>
          <Element>
            <String>${tempRange[1]}°</String>
            <Attributes>
              <Font Family="Arial" Size="7" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
              <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
            </Attributes>
          </Element>
        </StyledText>
      </TextObject>
      <Bounds X="2633.989" Y="1450.866" Width="327.3574" Height="260"/>
    </ObjectInfo>`;
  }

  let lowTemp = '';
  if (temp !== tempRange[0]) {
    lowTemp = `<ObjectInfo>
    <TextObject>
      <Name>TEXT_1</Name>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
      <LinkedObjectName></LinkedObjectName>
      <Rotation>Rotation0</Rotation>
      <IsMirrored>False</IsMirrored>
      <IsVariable>False</IsVariable>
      <HorizontalAlignment>Left</HorizontalAlignment>
      <VerticalAlignment>Middle</VerticalAlignment>
      <TextFitMode>None</TextFitMode>
      <UseFullFontHeight>True</UseFullFontHeight>
      <Verticalized>False</Verticalized>
      <StyledText>
        <Element>
          <String>${tempRange[0]}°</String>
          <Attributes>
            <Font Family="Arial" Size="7" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
          </Attributes>
        </Element>
      </StyledText>
    </TextObject>
    <Bounds X="345.0824" Y="1453.6" Width="326.2734" Height="260"/>
  </ObjectInfo>`;
  }

  return `<?xml version="1.0" encoding="utf-8"?>
  <DieCutLabel Version="8.0" Units="twips" MediaType="Default">
    <PaperOrientation>Portrait</PaperOrientation>
    <Id>Small30334</Id>
    <PaperName>30334 2-1/4 in x 1-1/4 in</PaperName>
    <DrawCommands>
      <RoundRectangle X="0" Y="0" Width="3240" Height="1800" Rx="270" Ry="270"/>
    </DrawCommands>
    <ObjectInfo>
      <BarcodeObject>
        <Name>BARCODE</Name>
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
        <BackColor Alpha="255" Red="255" Green="255" Blue="255"/>
        <LinkedObjectName></LinkedObjectName>
        <Rotation>Rotation0</Rotation>
        <IsMirrored>False</IsMirrored>
        <IsVariable>False</IsVariable>
        <Text>${url}</Text>
        <Type>QRCode</Type>
        <Size>Medium</Size>
        <TextPosition>None</TextPosition>
        <TextFont Family="Arial" Size="11" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
        <CheckSumFont Family="Arial" Size="10" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
        <TextEmbedding>None</TextEmbedding>
        <ECLevel>0</ECLevel>
        <HorizontalAlignment>Left</HorizontalAlignment>
        <QuietZonesPadding Left="0" Right="0" Top="0" Bottom="0"/>
      </BarcodeObject>
      <Bounds X="392.4262" Y="0" Width="1352.078" Height="1076.102"/>
    </ObjectInfo>
    <ObjectInfo>
      <TextObject>
        <Name>TEXT</Name>
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
        <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
        <LinkedObjectName></LinkedObjectName>
        <Rotation>Rotation0</Rotation>
        <IsMirrored>False</IsMirrored>
        <IsVariable>False</IsVariable>
        <HorizontalAlignment>Left</HorizontalAlignment>
        <VerticalAlignment>Middle</VerticalAlignment>
        <TextFitMode>AlwaysFit</TextFitMode>
        <UseFullFontHeight>True</UseFullFontHeight>
        <Verticalized>False</Verticalized>
        <StyledText>
          <Element>
            <String>    ${
              material.indexOf('PLA') >= 0 ? 'Material' : 'Item'
            }: ${material.replace('Ltd', '').toUpperCase()}</String>
            <Attributes>
              <Font Family="Arial" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
              <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
            </Attributes>
          </Element>
    ${
      colors.length > 0
        ? `<Element>
    <String>
    Colors: ${colors[0].toTitleCase()}${colors[0] !== '' ? ', ' : ''}${
            colors[1] ? colors[1].toTitleCase() : ''
          }</String>
          <Attributes>
            <Font Family="Arial" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
          </Attributes>
        </Element>
        ${colorString}`
        : `<Element>
      <String>
    ${material.indexOf('parts') >= 0 ? 'Type' : 'Color'}: </String>
            <Attributes>
              <Font Family="Arial" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
              <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
            </Attributes>
          </Element>
          <Element>
            <String>${(colorName || color).toTitleCase()}</String>
            <Attributes>
              <Font Family="Arial" Size="13" Bold="True" Italic="False" Underline="False" Strikeout="False"/>
              <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
            </Attributes>
          </Element>`
    }
          <Element>
            <String>
    ${material.indexOf('PLA') >= 0 ? 'Diameter' : 'Size'}: ${diameter}</String>
            <Attributes>
              <Font Family="Arial" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
              <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
            </Attributes>
          </Element>
          ${
            plasticSpool
              ? `
          <Element>
            <String>
    PLASTIC SPOOL</String>
            <Attributes>
              <Font Family="Arial" Size="10" Bold="True" Italic="False" Underline="False" Strikeout="False"/>
              <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
            </Attributes>
          </Element>`
              : null
          }
          <Element>
            <String>
    ${temp ? `Temp: ${tempRange[0]} ~ ${tempRange[1]}°` : ''}</String>
            <Attributes>
              <Font Family="Arial" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
              <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
            </Attributes>
          </Element>
        </StyledText>
      </TextObject>
      <Bounds X="1390.034" Y="0" Width="1790.503" Height="1044.021"/>
    </ObjectInfo>
    ${
      temp
        ? `${lowTemp}
    ${topTemp}
    <ObjectInfo>
      <TextObject>
        <Name>TEXT_3</Name>
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
        <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
        <LinkedObjectName></LinkedObjectName>
        <Rotation>Rotation0</Rotation>
        <IsMirrored>False</IsMirrored>
        <IsVariable>False</IsVariable>
        <HorizontalAlignment>Left</HorizontalAlignment>
        <VerticalAlignment>Middle</VerticalAlignment>
        <TextFitMode>None</TextFitMode>
        <UseFullFontHeight>True</UseFullFontHeight>
        <Verticalized>False</Verticalized>
        <StyledText>
          <Element>
            <String>${temp}°</String>
            <Attributes>
              <Font Family="Arial" Size="7" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
              <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
            </Attributes>
          </Element>
        </StyledText>
      </TextObject>
      <Bounds X="${linePos - 150}" Y="1453.6" Width="646.9238" Height="260"/>
    </ObjectInfo>
    <ObjectInfo>
      <TextObject>
        <Name>TEXT_4</Name>
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
        <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
        <LinkedObjectName></LinkedObjectName>
        <Rotation>Rotation0</Rotation>
        <IsMirrored>False</IsMirrored>
        <IsVariable>False</IsVariable>
        <HorizontalAlignment>Left</HorizontalAlignment>
        <VerticalAlignment>Middle</VerticalAlignment>
        <TextFitMode>ShrinkToFit</TextFitMode>
        <UseFullFontHeight>True</UseFullFontHeight>
        <Verticalized>False</Verticalized>
        <StyledText>
          <Element>
            <String>Recomended</String>
            <Attributes>
              <Font Family="Arial" Size="7" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
              <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
            </Attributes>
          </Element>
        </StyledText>
      </TextObject>
      <Bounds X="${
        linePos - 330 - (temp !== tempRange[1] ? 0 : 240)
      }" Y="1102.187" Width="790" Height="275"/>
    </ObjectInfo>
    <ObjectInfo>
      <ShapeObject>
        <Name>SHAPE</Name>
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
        <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
        <LinkedObjectName></LinkedObjectName>
        <Rotation>Rotation0</Rotation>
        <IsMirrored>False</IsMirrored>
        <IsVariable>False</IsVariable>
        <ShapeType>HorizontalLine</ShapeType>
        <LineWidth>20</LineWidth>
        <LineAlignment>LeftOrTop</LineAlignment>
        <FillColor Alpha="0" Red="0" Green="0" Blue="0"/>
      </ShapeObject>
      <Bounds X="336.6957" Y="1113.6" Width="2619.842" Height="600"/>
    </ObjectInfo>
    <ObjectInfo>
      <ImageObject>
        <Name>GRAPHIC</Name>
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
        <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
        <LinkedObjectName></LinkedObjectName>
        <Rotation>Rotation0</Rotation>
        <IsMirrored>False</IsMirrored>
        <IsVariable>False</IsVariable>
        <Image>iVBORw0KGgoAAAANSUhEUgAAACwAAAArCAYAAAADgWq5AAAABGdBTUEAA1teXP8meAAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAALKADAAQAAAABAAAAKwAAAABOTjuzAAAEr0lEQVRYCe2Zy08bRxzH1zbiEPFSIIBACBWViqCQiEukPChGnGkr3FRC/isQcIMD3LghxBUUQkSUW4J4xAgQaRs4AWopaioVVS2leUlBxJDwnnx/ht9m1p5Zr72xk0NH+mp2Hr/f7zPj3fXMrEcIYSSTvF7vOdjdhOqgK9BXUCGUDZHTHegl9BRahR5DT05OTt4hTzh5PJ5TGwJORDCsg+5AYUgkqDfofxu6kUhMy6Q6NUQQP/QEShRS1/8n+LrpNL75k8QzgNPz0OhHBJUHcAK/IxQjHocjYDi6Dv0NyUFScf0PYly1g44LDAcB6F0aYHkC3iLWtzpoBvZQh+iEN8At1N2DfNFtcjkrK8toamoyGhsbjdraWqOiosLIzc2NdNne3jbW19eN5eVlY3Z21hgfHzd2d3dlc9X1MSoDeJM8jG7EYE6rokeEBnq49iEeeUyen58vent7RTgchrmzRH37+vpESUlJjL+oWBS7Dl4tby9zAHIDOpZAz6McmAF8Pp9ob28XW1tbzigVvQi8tbVV4Fc0/SribaKuSGbTAU8qjCOOc3JyxOTkpAIhuaqxsTGRnZ1tB/3AFhig3+tg8/LyxNLSUnJkNlYLCwsC97wd9DcMbZlhgHqhpyrgjIwMMTMzYxPWXVMoFBIUQxUbdb9CXoI2ExVQ+YPGQHR3d7sjcmDd2dmpA6b671TAj1TAVVVVYn9/30FId1329vZEZWWlDnrcAgzQIuhYBTw6OuqOJAHr4eFhHfAh2GgVeJpQCKpgi4uLxeHhYQIh3XWlX7KgoEAHHWReLy7quSDngUDAwMMgV6X0OjMz02hubtbFuMYNBHyJC3Le0NAgF9Ny7ff7dXEucgMBf8EFOa+pqZGLabm2ifklAxAwbWliUmlpaUxdqivKysp0Ic5zA543j/RW5mrDwIrpQyFNV0dHRwbdy6qERzqyXKMZVq75HCwFVX5d1dnEpA1tJBFw+Ozakm1ublrK6ShsbGzowrzmBgL+iwtyvrpKO/P0prW1NV3AP7mBgH/jgpzPz8/LxbRcz83N6eL8bjZ8Tv90hYWFcf/paKX2WawlRkZGdLBHYPywlsDrgqBDUIxBdXW1ODg4cLdIcGAdZ7UWIkYznQFr18M9PT0OQrrr0tXVFTNZ0gQ2q4B96PBJdhzT09N2O45VcMXuOM5m+ZY0KsuIaU+H8wV306iwXlxcFORbFxf15sGK5ZYg4DNo213z1NSUImxyVRMTE/F2zQ+Zi3IzyZUYUSn0QjdiOpfo6OgQ+AtNjhJWdC7R1tYW71ziPzAUy2xKYOqAjn7I9uSnvLxcDAwMiJ2dHcfgBNrf3y+wCrS7BajN+ckPjwhG9Nag95+tczoICQaDYmhoSKysrFiOrnC2FqkbHBwULS0t8X5+jkMxI7tkZuFcO8PcAYZ0sJLu00slLDGZiQFVOYBvQM8gnoFU5f8ixjUVA9c5AqbOcFQA3U8RNJ3A34XyGUyXOwZmB3BKD+MC9LFmeRG+Yo5VOV50njAwO0CQryH6LpHMVySyGYbq2Z/TnIGVJ/DcaJdL3+nq0e8yRN/pLkA5EKU30CvoD+gX6EfoZ9ff6eDk/5TKGXgPO8ekEsVsU5YAAAAASUVORK5CYII=</Image>
        <ScaleMode>Uniform</ScaleMode>
        <BorderWidth>0</BorderWidth>
        <BorderColor Alpha="255" Red="0" Green="0" Blue="0"/>
        <HorizontalAlignment>Center</HorizontalAlignment>
        <VerticalAlignment>Center</VerticalAlignment>
      </ImageObject>
      <Bounds X="${linePos - 100}" Y="1322.734" Width="200" Height="200"/>
    </ObjectInfo>`
        : ''
    }
  </DieCutLabel>`;
};

export {
  pickListXml,
  boxLabelXml,
  orderLabelXml,
  firstOrderXml,
  orderWith285Xml,
};

const createColorStrings = colors => {
  let tmp = {};
  colors.forEach(c => {
    if (!tmp[c]) {
      tmp[c] = 0;
    }
    tmp[c]++;
  });
  colors = Object.keys(tmp).map(key => {
    return key + '  x ' + tmp[key];
  });

  colors.sort(function (a, b) {
    return b.length - a.length;
  });

  colors.reverse();

  if (colors.length % 2 !== 0) {
    colors.unshift('');
  }

  let sortedArray = new Array(colors.length);
  for (let i = 0; i < colors.length; i += 2) {
    sortedArray[i] = colors[i / 2];
    sortedArray[i + 1] = colors[colors.length - i / 2 - 1];
  }
  return sortedArray;
};
